import { useRef, useState } from 'react';
import { PlaylistSelector } from './components/playlist-selector';
import {
    spotifySearch,
    trackOperationCopy,
    trackOperationCustomise,
    trackOperationDelete,
    trackOperationMove,
} from '../../api';
import Draggable from 'react-draggable';
import { useDebouncedCallback } from 'use-debounce';

export const TrackModal = ({
    clearModal,
    initialisePlayer,
    isLoading,
    payload,
    playlistData,
    playlists,
    selectedPlaylistId,
    setLoading,
    track,
}) => {
    const [loadingInput, setLoadingInput] = useState('');

    const [formFields, setFormFields] = useState({
        title: track.properties?.title ?? '',
        artist: track.properties?.artist ?? '',
        cover: track.properties?.cover ?? '',
    });
    const [operation, setOperation] = useState({});
    const [covers, setCovers] = useState([
        track.properties?.cover
            ? track.properties?.cover
            : `${window.configuration.website}/server/default_cover.jpg`,
        `${window.configuration.website}/server/default_cover.jpg`,
        `${window.configuration.website}/server/default_cover.jpg`,
        `${window.configuration.website}/server/default_cover.jpg`,
    ]);

    const onCloseModal = () => {
        initialisePlayer(true);
        clearModal();
    };

    const debouncedTrackOperationCustomise = useDebouncedCallback(
        async (field, elementId, playlistId, data) => {
            setLoadingInput(field);
            await trackOperationCustomise(elementId, playlistId, data);
            setTimeout(() => setLoadingInput(''), 200);
        },
        1000
    );

    const onUpdateForm = (field, value) => {
        setFormFields({ ...formFields, [field]: value });

        debouncedTrackOperationCustomise(field, track.id, playlistData.id, {
            [field]: value,
        });
    };

    const updateOperation = (operationName, playlistId) => {
        if (!playlistId) {
            setOperation({});
        } else {
            setOperation({ name: operationName, playlistId });
        }
    };

    const runOperation = (operationName) => {
        switch (operationName) {
            case 'copy':
                if (operation.name === 'copy') {
                    updateOperation(undefined);
                    trackOperationCopy(
                        track.id,
                        playlistData.id,
                        operation.playlistId
                    ).then(({ data }) => {
                        //
                    });
                }
                break;
            case 'move':
                if (operation.name === 'move') {
                    updateOperation(undefined);
                    trackOperationMove(
                        track.id,
                        playlistData.id,
                        operation.playlistId
                    ).then(({ data }) => {
                        //
                    });
                }
                break;
            case 'delete':
                if (operation.name === 'delete') {
                    updateOperation(undefined);
                    trackOperationDelete(track.id, playlistData.id).then(
                        ({ data }) => {
                            if (data.success) {
                                window.location.reload();
                            }
                        }
                    );
                }
                break;
            default:
            // nothing
        }
    };

    const getCovers = () => {
        let query = track.displayName;

        if (formFields.title) {
            query = `${formFields.title} ${formFields?.artist ?? ''}`;
        }

        spotifySearch(query, 4).then(({ data }) => {
            setCovers(data.data.map((element) => element.cover));
        });
    };

    return (
        <div className="Modal Track">
            <div className="Modal--header">
                <div className="Modal--close" onClick={() => onCloseModal()}>
                    <i className="fa fa-angle-double-down" />
                </div>
                <div className="Modal--title">
                    <h1>Track</h1>
                </div>
            </div>
            <div className={'Modal__container'}>
                <div className={'Track__container'}>
                    <div className={'Track__info'}>
                        <div className={'Track__info__images'}>
                            <img
                                alt={''}
                                src={track.img}
                                className={`Track__info__images__img ${
                                    formFields.cover
                                        ? ' Track__info__images__img--mini'
                                        : ''
                                }`}
                                onClick={() =>
                                    formFields.cover &&
                                    setFormFields({ cover: undefined })
                                }
                            />
                            {formFields.cover ? (
                                <img
                                    className={'Track__info__images__img'}
                                    alt={''}
                                    src={formFields.cover}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                        <span>{track.title}</span>
                        <span style={{ fontStyle: 'italic' }}>
                            {track.artist}
                        </span>
                    </div>
                    <div className={'Track__custom'}>
                        <div className={'Track__label'}>Título</div>

                        <div className={'Track__custom__input'}>
                            <input
                                onChange={(e) => {
                                    onUpdateForm('title', e.target.value);
                                }}
                                value={formFields?.title ?? ''}
                            />
                            {loadingInput === 'title' && (
                                <div
                                    className={
                                        'Track__custom__input__action Track__custom__input__action--loading'
                                    }
                                >
                                    <i
                                        className={
                                            'fa fa-refresh fa-spin fa-fw'
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        <div className={'Track__label'}>Artista</div>
                        <div className={'Track__custom__input'}>
                            <input
                                onChange={(e) => {
                                    onUpdateForm('artist', e.target.value);
                                }}
                                value={formFields?.artist ?? ''}
                            />
                            {loadingInput === 'artist' && (
                                <div
                                    className={
                                        'Track__custom__input__action Track__custom__input__action--loading'
                                    }
                                >
                                    <i
                                        className={
                                            'fa fa-refresh fa-spin fa-fw'
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        <div className={'Track__label'}>
                            <span>Carátula</span>
                            <i
                                className={'fa fa-eye'}
                                onClick={() => getCovers()}
                            />
                        </div>
                        <div
                            className={
                                'Track__custom__input Track__custom__input--images'
                            }
                        >
                            {covers?.map((cover, index) => {
                                const isSelected = formFields?.cover === cover;
                                const className = `Track__custom__input__image ${isSelected ? 'Track__custom__input__image--selected' : ''}`;
                                return (
                                    <img
                                        alt={''}
                                        className={className}
                                        key={`${index}`}
                                        src={cover}
                                        onClick={() => {
                                            onUpdateForm('cover', cover);
                                        }}
                                    />
                                );
                            })}
                        </div>

                        <div className={'Track__section'}>Operaciones</div>
                        <div className={'Track__label'}>
                            Copiar a a la lista
                        </div>
                        <div
                            className={
                                'Track__custom__input Track__custom__input--blocks'
                            }
                        >
                            <PlaylistSelector
                                {...{
                                    playlists,
                                    selectedPlaylistId:
                                        operation.name === 'copy'
                                            ? selectedPlaylistId
                                            : undefined,
                                    ignoredPlaylists: [playlistData.id],
                                }}
                                onChange={(selectedPlaylistId) =>
                                    updateOperation('copy', selectedPlaylistId)
                                }
                            />
                            {operation?.name === 'copy' && (
                                <>
                                    <div
                                        className={
                                            'Track__custom__input__slider__container'
                                        }
                                    >
                                        <InputSlider
                                            icon={'angle-double-right'}
                                            operation={'copy'}
                                            onActive={() =>
                                                runOperation('copy')
                                            }
                                        />
                                    </div>
                                    <div
                                        className={
                                            'Track__custom__input__warning'
                                        }
                                    >
                                        Copiar canción a otra lista
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={'Track__label'}>Mover a la lista</div>
                        <div
                            className={
                                'Track__custom__input Track__custom__input--blocks'
                            }
                        >
                            <PlaylistSelector
                                {...{
                                    playlists,
                                    selectedPlaylistId:
                                        operation?.name === 'move'
                                            ? selectedPlaylistId
                                            : undefined,
                                    ignoredPlaylists: [playlistData.id],
                                }}
                                onChange={(selectedPlaylistId) =>
                                    updateOperation('move', selectedPlaylistId)
                                }
                            />
                            {operation?.name === 'move' && (
                                <>
                                    <div
                                        className={
                                            'Track__custom__input__slider__container'
                                        }
                                    >
                                        <InputSlider
                                            icon={'angle-double-right'}
                                            operation={'move'}
                                            onActive={() =>
                                                runOperation('move')
                                            }
                                        />
                                    </div>
                                    <div
                                        className={
                                            'Track__custom__input__warning'
                                        }
                                    >
                                        Quitar cancion de la lista y moverla a
                                        otra lista
                                    </div>
                                </>
                            )}
                        </div>

                        <div className={'Track__label'}>
                            Eliminar de la lista
                        </div>
                        <div className={'Track__custom__input'}>
                            <div
                                className={
                                    'Track__custom__input__slider__container'
                                }
                            >
                                <InputSlider
                                    icon={'angle-double-right'}
                                    operation={'delete'}
                                    onActive={() => {
                                        setOperation({ name: 'delete' });
                                        runOperation('delete');
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const InputSlider = ({ icon, operation, onActive }) => {
    const sliderRef = useRef(null);
    const debouncedOnActive = useDebouncedCallback(() => {
        onActive?.();
    }, 1000);

    const _handleDrag = (e, ui) => {
        const containerWidth = sliderRef.current?.parentNode?.clientWidth ?? 0;
        const sliderWidth = sliderRef.current?.clientWidth ?? 0;

        if (ui.lastX + sliderWidth >= containerWidth) {
            debouncedOnActive();
        }
    };

    return (
        <Draggable
            bounds={'.Track__custom__input__slider__container'}
            axis="x"
            handle={'.Track__custom__input__slider'}
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x: 0, y: 0 }}
            grid={[50, 50]}
            scale={2}
            onDrag={_handleDrag}
        >
            <div
                ref={sliderRef}
                className={`Track__custom__input__slider Track__custom__input__slider--${operation}`}
            >
                <i className={`fa fa-${icon}`} />
            </div>
        </Draggable>
    );
};
