export const PlaylistSelector = ({
    playlists,
    selectedPlaylistId,
    onChange,
    ignoredPlaylists,
}) => {
    const _onChange = (event) => {
        onChange?.(
            event.target.value === '__' ? undefined : event.target.value
        );
    };

    let playlistList = playlists ?? [];

    if (ignoredPlaylists?.length > 0) {
        playlistList = playlists.filter(
            (playlist) => !ignoredPlaylists.includes(playlist.id)
        );
    }

    return (
        <div className={'PlaylistSelector'}>
            <select onChange={(e) => _onChange(e)}>
                <option value="__" selected={!selectedPlaylistId}>
                    Selecciona una lista...
                </option>

                {playlistList?.map((playlist) => (
                    <option
                        value={playlist?.id}
                        id={playlist?.id}
                        selected={selectedPlaylistId === playlist?.id}
                    >
                        {playlist.name} ({playlist.user})
                    </option>
                ))}
            </select>
        </div>
    );
};
