import React from 'react';
import ReactDOM from 'react-dom/client';
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
import Player from './components/Player';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import { register } from 'register-service-worker';
import { Account } from './components/account';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './styles/base.scss';

const router = createBrowserRouter([
    {
        path: '/account',
        element: <Account />,
    },
    {
        path: '/:playlist/:song',
        element: <Player />,
    },
    {
        path: '/:playlist',
        element: <Player />,
    },
    {
        path: '/',
        element: <Player />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId="20043882366-e97u3f3g9povi47qldsq2p9n868sc7dh.apps.googleusercontent.com">
            <RouterProvider router={router} />
        </GoogleOAuthProvider>
    </React.StrictMode>
);

// register('/service-worker.js', {
//   registrationOptions: { scope: './' },
//   ready (registration) {
//     console.log('Service worker is active.',registration)
//   },
//   registered (registration) {
//     console.log('Service worker has been registered.',registration)
//   },
//   cached (registration) {
//     console.log('Content has been cached for offline use.',registration)
//   },
//   updatefound (registration) {
//     console.log('New content is downloading.',registration)
//   },
//   updated (registration) {
//     console.log('New content is available; please refresh.',registration)
//   },
//   offline () {
//     console.log('No internet connection found. App is running in offline mode.')
//   },
//   error (error) {
//     console.error('Error during service worker registration:', error)
//   }
// })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
