import { useEffect, useState } from 'react';
import { Custom } from './custom';
import { DEFAULT_VIEW_TYPE, scrollToTop } from '../../utils';
import { setCustomConfiguration, updatePlaylist } from '../../api';
import { useDebouncedCallback } from 'use-debounce';

export const PlaylistToolbar = ({
    filterTracks,
    shuffle,
    deShuffle,
    sortByLatest,
    isEmpty,
    playlistData,
    setPlaylistData,
    reloadCurrentPlaylist,
}) => {
    const [isRunningPlaylistSync, setIsRunningPlaylistSync] = useState(false);
    const [viewType, setViewType] = useState(
        playlistData?.viewType ?? DEFAULT_VIEW_TYPE
    );
    const [isExtraEnabled, setIsExtraEnabled] = useState(false);
    const [query, setQuery] = useState('');

    const debouncedSetCustomConfiguration = useDebouncedCallback(
        async (config) => {
            setCustomConfiguration(config);
        },
        1000
    );

    const updatePlaylistConfiguration = (customConfig) => {
        const newConfig = { ...playlistData, ...customConfig };
        setPlaylistData(newConfig);
        debouncedSetCustomConfiguration(newConfig);
    };

    const handleKeyUp = (event) => {
        const query = event.target.value;
        setQuery(query);
        filterTracks(query);
        scrollToTop();
    };

    const toggleExtraPanel = (state) => {
        if (state) {
            setIsExtraEnabled(true);

            const toolBarHeight =
                document.querySelector('.Toolbar').offsetHeight - 1 + 'px';

            document.querySelector('.Toolbar__extra').style.bottom =
                toolBarHeight;
        } else {
            setIsExtraEnabled(false);
        }
    };

    useEffect(() => {
        if (playlistData?.viewType) {
            setViewType(playlistData.viewType);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlistData?.viewType]);

    useEffect(() => {
        if (viewType && playlistData?.id) {
            updatePlaylistConfiguration({ ...playlistData, viewType });
            setPlaylistData({ ...playlistData, viewType });
        }
    }, [viewType]);

    const runUpdatePlaylist = () => {
        if (isRunningPlaylistSync) {
            return;
        }

        setIsRunningPlaylistSync(true);

        updatePlaylist(playlistData.id)
            .then(({ data }) => {
                if (data.success) {
                    reloadCurrentPlaylist(() => sortByLatest());
                }
            })
            .finally(() => {
                setIsRunningPlaylistSync(false);
            });
    };

    const actions = {
        main: [
            {
                icon: 'fa-random',
                action: () => {
                    shuffle();
                    toggleExtraPanel(false);
                },
            },
            {
                icon: `fa-angle-${isExtraEnabled ? 'down' : 'up'}`,
                action: () => toggleExtraPanel(!isExtraEnabled),
                style: { marginLeft: '1em' },
            },
        ],
        extra: [
            {
                icon: 'fa-sort-alpha-asc',
                name: 'Normal',
                action: () => {
                    deShuffle();
                    toggleExtraPanel(false);
                },
            },
            {
                icon: 'fa-th-list',
                name: 'Lista',
                checker: (id) => id === viewType,
                value: 'list',
                action: () => {
                    setViewType('list');
                    toggleExtraPanel(false);
                },
            },
            {
                icon: 'fa-th-large',
                name: 'Cuadrícula',
                checker: (id) => id === viewType,
                value: 'carousel',
                action: () => {
                    setViewType('carousel');
                    toggleExtraPanel(false);
                },
            },
            {
                icon: 'fa-star-o',
                name: 'Últimas',
                action: () => {
                    sortByLatest();
                    toggleExtraPanel(false);
                },
            },
            {
                icon: `${isRunningPlaylistSync ? 'fa-spin ' : ''}fa-download`,
                name: 'Descargar',
                action: () => runUpdatePlaylist(),
            },
            {
                icon: `${isRunningPlaylistSync ? 'fa-spin ' : ''}fa-refresh`,
                name: 'Recargar',
                action: () => window.location.reload(),
            },
        ],
    };

    return (
        <div className={'Toolbar'}>
            <div
                className={'Toolbar__extra'}
                style={{ display: isExtraEnabled ? 'block' : 'none' }}
            >
                <div className={'Toolbar__extra__actions'}>
                    <Actions actions={actions.extra} />
                </div>
                <div className={'Toolbar__extra__options'}>
                    <Custom
                        {...{
                            playlistData,
                            updatePlaylistConfiguration,
                        }}
                    />
                </div>
            </div>

            <div className="Toolbar__main">
                <form
                    className={'Toolbar__search'}
                    onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        return false;
                    }}
                >
                    <i
                        className={'fa fa-search'}
                        onClick={() => {
                            document.querySelector('#search_input').blur();
                        }}
                    ></i>
                    <div className={'Toolbar__search__input'}>
                        <input
                            value={query}
                            id={'search_input'}
                            placeholder="Buscar..."
                            type="text"
                            onChange={handleKeyUp}
                            style={isEmpty ? { color: 'red' } : {}}
                        />
                        <i
                            className={'fa fa-times'}
                            style={{ visibility: query ? 'visible' : 'hidden' }}
                            onClick={() => {
                                setQuery('');
                                filterTracks('');
                            }}
                        ></i>
                    </div>
                </form>
                <Actions actions={actions.main} />
            </div>
        </div>
    );
};

const Actions = ({ actions }) => {
    return actions.map((action) => {
        const selected = action.checker?.(action.value) ?? false;
        return (
            <div
                className={`Toolbar__button ${
                    selected ? 'Toolbar__button--selected' : ''
                }`}
                key={action.icon}
                style={action.style}
                onClick={() => action.action()}
            >
                <i
                    id={`button-${action.icon}`}
                    className={`fa ${action.icon}`}
                ></i>
                <span>{action.name}</span>
            </div>
        );
    });
};
