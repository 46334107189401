import {useEffect, useState} from 'react';
import {getColorByBgColor, isEmoji} from '../../utils';
import {ColorPicker} from './color-picker';
import {useDebounce} from '@uidotdev/usehooks';
import Marquee from "react-fast-marquee";

export const MARQUEE_ENABLED = false;

export const Custom = ({playlistData, updatePlaylistConfiguration}) => {
    const [emoji, setEmoji] = useState(playlistData?.emoji ?? '🎵');
    const [isEmojiInputSelected, setIsEmojiInputSelected] = useState(false);
    const [theme, setTheme] = useState(playlistData?.theme ?? '#460b00');
    const [iconColor, setIconColor] = useState(
        playlistData?.iconColor ?? '#FFFFFF'
    );
    const [marqueeEnabled, setMarqueeEnabled] = useState(playlistData.marquee ?? false);

    const debouncedTheme = useDebounce(theme, 10);
    const debouncedIconColor = useDebounce(iconColor, 10);

    const updateIcon = () => {
        document.querySelector('.Emoji__input').style.backgroundColor =
            iconColor;
    };
    useEffect(() => {
        const customConfig = {
            ...playlistData,
            emoji,
            theme,
            iconColor,
            marquee: marqueeEnabled
        };

        if (customConfig.id) {
            updatePlaylistConfiguration(customConfig);
            updateIcon();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emoji, debouncedTheme, debouncedIconColor, marqueeEnabled]);

    useEffect(() => {
        playlistData?.emoji && setEmoji(playlistData.emoji);
        playlistData?.theme && setTheme(playlistData.theme);
        playlistData?.iconColor && setIconColor(playlistData.iconColor);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlistData]);

    const handleEmojiInput = () => {
        setIsEmojiInputSelected(true);
        document.querySelector('#emoji-input').value = '';
        const emojiInput = document.querySelector('#emoji-input');
        emojiInput.focus();
    };

    const handleTextInputChange = (event) => {
        const input = event.target.value ?? '';

        if (isEmoji(input ?? '')) {
            setEmoji(input);
            document.querySelector('#emoji-input').blur();
            setIsEmojiInputSelected(false);
        } else {
        }
    };

    return (
        <div className={'Custom'}>
            <div className={'Custom__row'}>
                <div
                    className={`Emoji__input ${
                        isEmojiInputSelected ? 'Emoji__input--selected' : ''
                    }`}
                    onClick={handleEmojiInput}
                >
                    {emoji && !isEmojiInputSelected && (
                        <div className={'Emoji__display'}>{emoji}</div>
                    )}
                    {(!emoji || isEmojiInputSelected) && <span>Elige Emoji</span>}
                    <input
                        id={'emoji-input'}
                        type={'text'}
                        onChange={handleTextInputChange}
                        onBlur={() => setIsEmojiInputSelected(false)}
                    />
                </div>

                <div className={'ColorSelector'}>
                    <ColorPicker
                        color={iconColor}
                        onChange={(e) => setIconColor(e)}
                    />
                    <div className={'ColorPicker__label'}>Icono</div>
                </div>

                <div className={'ColorSelector'}>
                    <ColorPicker color={theme} onChange={(e) => setTheme(e)}/>
                    <div className={'ColorPicker__label'}>Tema</div>
                </div>

                { MARQUEE_ENABLED && <div className={"EnableMarquee"}
                     style={{backgroundColor: playlistData.theme, color: getColorByBgColor(playlistData.theme)}}
                     onClick={() => setMarqueeEnabled(!marqueeEnabled)}>
                    {playlistData.marquee ? <Marquee>
                        <div className={"EnableMarquee__text"}>Hola</div>
                    </Marquee> : <div className={"EnableMarquee__text"}>Hola</div>}
                </div>}
            </div>
        </div>
    );
};
