import axios from 'axios';

const baseUrl = `${window.configuration.website}/server/api.php?`;

export const getConfigApi = () => axios.get(`${baseUrl}op=config`);
export const getPlaylist = (id) =>
    axios.get(`${baseUrl}op=playlist&id=${id ?? ''}`);
export const getAllPlaylists = () => axios.get(`${baseUrl}op=familyPlaylists`);

export const setCustomConfiguration = (customConfig) => {
    const config = { ...customConfig };
    delete config?.elements;
    delete config?.tracks;

    return axios.post(`${baseUrl}op=save-playlist-config`, config);
};

export const spotiSearch = (query) =>
    axios.get(`${baseUrl}op=spoti-search&query=${query}`);

export const spotiDownload = (id, playlist) =>
    axios.get(`${baseUrl}op=spoti-download&id=${id}&playlist=${playlist}`);

export const youtubeSearch = (query) =>
    axios.get(`${baseUrl}op=search-youtube&q=${query}`);

export const addYoutubeToPlaylist = ({ youtubeId, playlistId, cover }) => {
    const path = `${baseUrl}op=add-youtube&id=${youtubeId}&playlistId=${playlistId}&cover=${cover}`;

    return axios.get(path);
};

export const songInfo = (data) =>
    axios.get(`${baseUrl}op=song-info&data=${btoa(data)}`);

export const updatePlaylist = (playlist) =>
    axios.get(`${baseUrl}op=playlist-runner&id=${playlist}`);

export const spotifySearch = (query, limit = 1) =>
    axios.get(`${baseUrl}op=search-spotify&q=${query}&limit=${limit}`);

export const trackOperationCustomise = (elementId, playlistId, data) =>
    axios.post(`${baseUrl}op=track-operation&operation=customise`, {
        playlistId,
        id: elementId,
        data,
    });

export const trackOperationCopy = (elementId, originalPlaylistId, playlistId) =>
    axios.get(
        `${baseUrl}op=track-operation&operation=copy&id=${elementId}&originalPlaylistId=${originalPlaylistId}&playlistId=${playlistId}`
    );
export const trackOperationMove = (elementId, originalPlaylistId, playlistId) =>
    axios.get(
        `${baseUrl}op=track-operation&operation=move&id=${elementId}&originalPlaylistId=${originalPlaylistId}&playlistId=${playlistId}`
    );
export const trackOperationDelete = (elementId, playlistId) =>
    axios.get(
        `${baseUrl}op=track-operation&operation=delete&id=${elementId}&playlistId=${playlistId}`
    );
