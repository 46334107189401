import { GoogleLogin } from '@react-oauth/google';

export const MenuModal = ({ clearModal }) => {
    return (
        <div className="Modal Menu">
            <div className="Modal--header">
                <div className="Modal--close" onClick={() => clearModal()}>
                    <i className="fa fa-angle-double-down" />
                </div>
                <div className="Modal--title">
                    <h1>Menu</h1>
                </div>
            </div>
            <div className={'Modal__container'}>
                <div className="Menu__signin">
                    <GoogleLogin
                        onSuccess={(credentialResponse) => {
                            console.log(credentialResponse);
                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
