import { generateEmojiLink } from '../../utils';

export const PlaylistsModal = ({ playlists, clearModal }) => {
    if (!playlists.length) {
        return null;
    }

    return (
        <div className="Modal Playlists">
            <div className="Modal--header">
                <div className="Modal--close" onClick={() => clearModal()}>
                    <i className="fa fa-angle-double-down" />
                </div>
                <div className="Modal--title">
                    <h1>Listas</h1>
                </div>
            </div>

            <div className={'Modal__container'}>
                <div className={'List'}>
                    {playlists?.map(({ id, name, root, iconColor, emoji }) => (
                        <a
                            className={'List__item'}
                            href={`/${id}`}
                            key={`${root}--${name}--pl`}
                        >
                            <img
                                alt={`${name}-${root}`}
                                src={generateEmojiLink(iconColor, emoji, 300)}
                            />

                            <span className="List__item__name">{name}</span>
                            {root && (
                                <span className="List__item__user">{root}</span>
                            )}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};
