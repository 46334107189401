import React, { useCallback, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useEffect } from 'react';

export const ColorPicker = ({ color, onChange, className }) => {
    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    return (
        <div className={`ColorPicker ColorPicker---${className}`}>
            {isOpen && (
                <div className="ColorPicker__popover" ref={popover}>
                    <HexColorPicker color={color} onChange={onChange} />
                </div>
            )}
            <div
                className={`ColorPicker__swatch ${
                    isOpen ? `ColorPicker__swatch--selected` : ''
                }`}
                style={{ backgroundColor: color }}
                onClick={() => toggle(true)}
            />
        </div>
    );
};

// Improved version of https://usehooks.com/useOnClickOutside/
export const useClickOutside = (ref, handler) => {
    useEffect(() => {
        let startedInside = false;
        let startedWhenMounted = false;

        const listener = (event) => {
            // Do nothing if `mousedown` or `touchstart` started inside ref element
            if (startedInside || !startedWhenMounted) return;
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) return;

            handler(event);
        };

        const validateEventStart = (event) => {
            startedWhenMounted = ref.current;
            startedInside = ref.current && ref.current.contains(event.target);
        };

        document.addEventListener('mousedown', validateEventStart);
        document.addEventListener('touchstart', validateEventStart);
        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('mousedown', validateEventStart);
            document.removeEventListener('touchstart', validateEventStart);
            document.removeEventListener('click', listener);
        };
    }, [ref, handler]);
};
